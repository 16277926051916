<template>
  <div class="associate">
    <MobileTable
      v-model:selection="store.state.resident.selectedResidentsApartments"
      :label="$route.name"
      :columns="resident.columns"
      :data="store.state.resident.residentsApartments"
    />
    <DesktopTable
      v-model:selection="store.state.resident.selectedResidentsApartments"
      :label="$route.name"
      :columns="resident.columns"
      :data="store.state.resident.residentsApartments"
    />
    <Actions :items="resident.actions" />
    <BasicModal v-model:visible="resident.showDialog">
      <template v-slot:dialog>
        <BasicTitle title="Apartamentos" />
        <BasicSubtitle
          subtitle="Los campos señalados con (*) son obligatorios."
        />
        <div class="container-form">
          <BasicLabel label="Apartamento *" />
          <BorderSelect
            v-model="resident.data.apartment"
            label="Apartamento *"
            :options="resident.getApartments()"
          />
          <FormError
            :show="resident.rules.apartment"
            message="Seleccione un apartamento"
          />
          <BasicLabel label="Propietario / Inquilino *" />
          <BorderSelect
            v-model="resident.data.owner"
            label="Propietario / Inquilino *"
            :options="resident.owners"
          />
          <FormError
            :show="resident.rules.owner"
            message="Seleccione el tipo de residente"
          />
          <PrimaryButton label="Asociar" :click="onSave" />
        </div>
      </template>
    </BasicModal>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import BasicTitle from "../../widgets/title/BasicTitle";
import BasicSubtitle from "../../widgets/subtitle/BasicSubtitle";
import BasicLabel from "../../widgets/label/BasicLabel";
import BorderInput from "../../widgets/input/BorderInput";
import BorderSelect from "../../widgets/select/BorderSelect";
import BorderInputPrefix from "../../widgets/input/BorderInputPrefix";
import BorderInputPhone from "../../widgets/input/BorderInputPhone";
import FormError from "../../widgets/error/FormError";
import BasicModal from "../../widgets/modal/BasicModal.vue";
import PrimaryButton from "../../widgets/button/PrimaryButton";
import { onBeforeMount, onBeforeUnmount, reactive, watchEffect } from "vue";
import store from "../../store";
import { actions } from "../../constants";

export default {
  components: {
    MobileTable,
    DesktopTable,
    Actions,
    BasicTitle,
    BasicSubtitle,
    BasicLabel,
    BorderInput,
    BorderSelect,
    BorderInputPrefix,
    BorderInputPhone,
    FormError,
    PrimaryButton,
    BasicModal,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onAssociate = async () => {
      store.commit("setSelectedResidentsApartments", []);
      store.commit("setLoading", true);
      const request = {
        buildingId: store.state.general.selectedBuilding,
        residentId: store.state.resident.selectedResidents[0].id,
      };
      await store.dispatch(actions.generalActions.apartments, request);
      store.commit("setLoading", false);
      resident.showDialog = true;
    };
    const onDeassociate = async () => {
      if (!store.state.resident.selectedResidentsApartments.length) {
        alert("Debe seleccionar al menos un registro!");
      } else {
        store.commit("setLoading", true);

        const request = {
          apartments: store.state.resident.selectedResidentsApartments.map(
            (item) => item.id
          ),
          id: store.state.resident.selectedResidents[0].id,
        };
        const response = await store.dispatch(
          actions.residentActions.deassociate,
          request
        );

        if (response.ok) {
          store.commit("setSelectedResidentsApartments", []);
          await store.dispatch(actions.residentActions.apartments, {
            id: store.state.resident.selectedResidents[0].id,
            buildingId: store.state.general.selectedBuilding,
          });
          store.commit("setLoading", false);
        } else {
          store.commit("setLoading", false);
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
        }
      }
    };

    const resident = reactive({
      owners: [
        { id: "P", name: "Propietario" },
        { id: "I", name: "Inquilino" },
      ],
      showDialog: false,
      data: {
        apartment: "",
        owner: "",
      },
      rules: {
        apartment: false,
        owner: false,
      },
      columns: [
        {
          field: "tower",
          header: "Torre",
          sort: true,
          center: true,
        },
        {
          field: "number",
          header: "Apartamento",
          sort: true,
          center: true,
        },
        {
          field: "owner",
          header: "Propietario / Inquilino (P o I)",
          sort: true,
          center: true,
        },
      ],
      actions: [
        {
          name: "Asociar",
          action: onAssociate,
          hidden: true,
        },
        {
          name: "Desasociar",
          action: onDeassociate,
          hidden: true,
        },
      ],
      getApartments: () => {
        return store.state.general.apartments.filter((item) => {
          const finded = store.state.resident.residentsApartments.find(
            (el) => el.id === item.id
          );
          if (!finded) return item;
          return false;
        });
      },
    });

    const validate = () => {
      let valid = true;

      if (!resident.data.apartment) {
        resident.rules.apartment = true;
        valid = false;
      }
      if (!resident.data.owner) {
        resident.rules.owner = true;
        valid = false;
      }

      return valid;
    };

    const onSave = async () => {
      if (!validate()) return;

      store.commit("setLoading", true);

      const request = {
        residentId: store.state.resident.selectedResidents[0].id,
        apartmentId: resident.data.apartment,
        indOwner: resident.data.owner === "P" ? true : false,
      };
      const response = await store.dispatch(
        actions.residentActions.associate,
        request
      );
      if (response.ok) {
        resident.data.apartment = "";
        resident.data.owner = "";
        resident.showDialog = false;
        await store.dispatch(actions.residentActions.apartments, {
          id: store.state.resident.selectedResidents[0].id,
          buildingId: store.state.general.selectedBuilding,
        });
        store.commit("setLoading", false);
      } else {
        store.commit("setLoading", false);
        alert(response.message);
      }
    };

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedResidentsApartments", []);
      store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(actions.residentActions.apartments, {
        id: store.state.resident.selectedResidents[0].id,
        buildingId: store.state.general.selectedBuilding,
      });
      store.commit("setLoading", false);
    });

    onBeforeUnmount(() => {
      store.commit("removeBreadcrumb");
    });

    watchEffect(() => {
      if (resident.data.apartment) resident.rules.apartment = false;
      if (resident.data.owner) resident.rules.owner = false;
    });

    return {
      store,
      resident,
      onSave,
    };
  },
};
</script>

<style scoped>
.container-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}
</style>
